import { css } from 'styled-components'

import type { CommonBoxProps, FontSize } from '@fortum/elemental-ui'
import { styles } from '@fortum/elemental-ui'

import { boxPropsToStyles, boxStyles } from '@/shared/style/boxStyles'
import { getClickColor, getFocusColor, getHoverColor } from '@/shared/utils/colors'

type CustomStyleProps = {
  /**
   * Text color.
   */
  size?: FontSize
  /**
   * Text color.
   */
  color?: string
  /**
   * Hide link underline in normal state.
   */
  noUnderline?: boolean
  /**
   * Hide link underline in both normal and hover states.
   */
  noUnderlineHover?: boolean
  /**
   * Hover styles.
   */
  hover?: CommonBoxProps
  /**
   * Focus styles.
   */
  focus?: CommonBoxProps
  /**
   * Active styles.
   */
  active?: CommonBoxProps

  isHoverDisabled?: boolean
}

export type LinkStyleProps = CommonBoxProps & CustomStyleProps

export const linkMixins = css<LinkStyleProps>`
  font-family: ${({ theme }) => theme.font.family};
  color: ${({ theme, color }) => color ?? theme.colors.textLink};
  text-decoration: ${({ noUnderline }) => (noUnderline ? 'none' : 'underline')};
  line-height: ${({ theme }) => theme.contentText.web.lineHeights.normal};
  transition-property: color, background, border;
  transition-timing-function: ${styles.transition.function.base};
  transition-duration: ${styles.transition.timing.base};

  ${boxStyles}

  &:hover {
    ${({ isHoverDisabled, noUnderlineHover, theme, color, hover }) =>
      isHoverDisabled
        ? ''
        : `
        text-decoration: ${noUnderlineHover ? 'none' : 'underline'};
        color: ${getHoverColor(theme, color ?? theme.colors.textLink)};
        ${hover ? boxPropsToStyles(hover) : ''}
    `}
  }

  &:focus {
    color: ${({ theme, color }) => getFocusColor(theme, color ?? theme.colors.textLink)};
    ${({ focus }) => focus && boxPropsToStyles(focus)}
  }

  &:active {
    color: ${({ theme, color }) => getClickColor(theme, color ?? theme.colors.textLink)};
    ${({ active }) => active && boxPropsToStyles(active)}
  }
`
