'use client'

import { Box, spacing } from '@fortum/elemental-ui'

import { StyledLink } from '@/shared/components/StyledLink'
import type { LinkEntry } from '@/shared/contentful/types'
import { getLinkEntryUrl } from '@/shared/contentful/utils'
import { useTheme } from '@/shared/hooks/useTheme'

type Props = {
  quickLinks: LinkEntry[]
}

/**
 * Footer quick links.
 */
export const QuickLinks = ({ quickLinks }: Props) => {
  const theme = useTheme()

  return (
    <Box tag="ul" display="flex" flexDirection="column" gap={spacing.xxxs}>
      {quickLinks.map(({ sys, ...fields }) => (
        <Box tag="li" key={sys.id}>
          <StyledLink
            href={getLinkEntryUrl(fields)}
            color={theme.colors.textLightOnDark}
            data-testid="footer-quick-link"
            isHoverDisabled
          >
            {fields.label}
          </StyledLink>
        </Box>
      ))}
    </Box>
  )
}
