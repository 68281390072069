import type { Interpolation } from 'styled-components'
import { css } from 'styled-components'

import type { BoxPropsKey, CommonBoxProps, PropsMappings } from '@fortum/elemental-ui'
import { BoxPropsMapping, generateBoxPropStyles, getCssProperties } from '@fortum/elemental-ui'

export const propsMappingToStyles = (mappings: PropsMappings) => (props: CommonBoxProps) => css`
  ${(Object.keys(props) as BoxPropsKey[])
    .filter((propName) => mappings[propName])
    .map((propName) => {
      const cssProperties = getCssProperties(mappings[propName])

      const propValue = props[propName]
      if (propValue === undefined) {
        return
      }

      return cssProperties
        .map((cssProperty) => generateBoxPropStyles(cssProperty, propValue))
        .join('\n')
    })}
`

export const boxPropsToStyles = propsMappingToStyles(BoxPropsMapping)

/**
 * Applies box styles based on box props. Allows creation of custom components with box props.
 * Copied from Elemental, as it's not exported from there.
 */
export const boxStyles: Interpolation<CommonBoxProps> = css<CommonBoxProps>`
  ${boxPropsToStyles}
`
