'use client'

import { Box, Grid, spacing } from '@fortum/elemental-ui'

import type { FooterEntry } from '@/shared/contentful/types'
import { useTheme } from '@/shared/hooks/useTheme'

import { FirstRow } from './parts/FirstRow'
import { SecondRow } from './parts/SecondRow'
import { ThirdRow } from './parts/ThirdRow'

type FooterProps = FooterEntry

/**
 * Application footer component.
 */
export const Footer = ({
  appLinks,
  freeTextTitle,
  freeText,
  socialLinksTitle,
  socialLinksCollection,
  legalLinksCollection,
  badgeImagesCollection,
  quickLinksTitle,
  quickLinksCollection,
  countryLinksCollection,
}: FooterProps) => {
  const theme = useTheme()

  return (
    <Box
      tag="footer"
      backgroundColor={theme.colors.backgroundLightOnDark}
      pt={{ default: 0, l: spacing.xxs }}
      data-testid="footer"
    >
      <Grid>
        <FirstRow
          appLinks={appLinks}
          quickLinksTitle={quickLinksTitle}
          quickLinksCollection={quickLinksCollection}
          freeTextTitle={freeTextTitle}
          freeText={freeText}
        />
        <SecondRow
          badgeImagesCollection={badgeImagesCollection}
          socialLinksTitle={socialLinksTitle}
          socialLinksCollection={socialLinksCollection}
        />
        <ThirdRow
          countryLinksCollection={countryLinksCollection}
          legalLinksCollection={legalLinksCollection}
        />
      </Grid>
    </Box>
  )
}
