'use client'

import type { ImageProps } from 'next/image'
import Image from 'next/image'
import { useState } from 'react'
import styled from 'styled-components'

import { Box } from '@fortum/elemental-ui'

import imageFallback from '@/images/media-not-found.svg'
import type { ImageEntry } from '@/shared/contentful/types'
import { getAssetUrl } from '@/shared/contentful/utils'

const resolveImageUrl = function (image: ImageEntry, maxWidth: string): string {
  const imageUrl = getAssetUrl(image.image)
  if (!imageUrl) {
    return imageFallback
  }
  return `${imageUrl}?w=${maxWidth}`
}

type OmitSrcAlt = Omit<ImageProps, 'src' | 'alt'>

export type ContentfulImageProps = OmitSrcAlt & {
  /**
   * Contentful image entry
   */
  imageEntry?: ImageEntry | null
  /**
   * Props passed to the wrapper component
   */
  wrapperProps?: BoxProps<
    never,
    | 'width'
    | 'height'
    | 'position'
    | 'maxWidth'
    | 'minWidth'
    | 'minHeight'
    | 'mh'
    | 'mt'
    | 'objectFit'
    | 'borderRadius'
  >
  /**
   * Image source URL
   */
  src?: string
  /**
   * Image alt text
   */
  alt?: string
  /**
   * Maximum width of the fetched image
   */
  maxWidth?: string
}

/**
 * Image component that handles Contentful image entries and Next.js image component.
 */
export const ContentfulImage = ({
  imageEntry,
  wrapperProps,
  src: initialSrc = '',
  alt: initialAlt = '',
  maxWidth = '1280',
  ...otherProps
}: ContentfulImageProps) => {
  const [src, setSrc] = useState(imageEntry ? resolveImageUrl(imageEntry, maxWidth) : initialSrc)
  const alt = imageEntry?.altText || initialAlt

  return (
    <Wrapper overflow="hidden" position="absolute" {...wrapperProps} data-testid="contentful-image">
      <StyledImage fill alt={alt} src={src} {...otherProps} onError={() => setSrc(imageFallback)} />
    </Wrapper>
  )
}

const Wrapper = styled(Box)`
  inset: 0;
`

const StyledImage = styled(Image)`
  width: 100%;
  object-fit: cover;
`
