'use client'

import { useRef } from 'react'
import type { ElementRef, ReactNode } from 'react'

import type { BoxProps } from '@fortum/elemental-ui'
import { Box, IconChevronDown, spacing, styles, timings } from '@fortum/elemental-ui'

export type Accordion2Props = {
  /**
   * Header content.
   */
  header: ReactNode
  /**
   * Panel content.
   */
  children: ReactNode
  /**
   * Unique panel id. Required for accessibility.
   */
  panelId: string
  /**
   * Accordion state. If not provided, state is handled internally.
   */
  isExpanded: boolean
  /**
   * Callback triggered on accordion click.
   */
  onToggle: VoidFunction
  /**
   * Box prop for container element.
   */
  boxProps?: BoxProps
  /**
   * Box prop for header element.
   */
  headerBoxProps?: BoxProps
  /**
   * Test id for header.
   */
  headerTestId?: string
}

/**
 * Generic unstyled accordion component.
 * Follows the ARIA accordion pattern: https://www.w3.org/WAI/ARIA/apg/patterns/accordion/
 */
export const Accordion2 = ({
  header,
  children,
  panelId,
  onToggle,
  isExpanded,
  boxProps,
  headerBoxProps,
  headerTestId,
}: Accordion2Props) => {
  const headerRef = useRef<ElementRef<'div'>>(null)

  return (
    <Box display="flex" flexDirection="column" {...boxProps}>
      <Box
        tag="button"
        onClick={onToggle}
        boxRef={headerRef}
        height="auto"
        backgroundColor="transparent"
        border="none"
        textAlign="left"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        gap={spacing.xxs}
        cursor="pointer"
        transitionProperty="color, background, border"
        transitionTimingFunction={styles.transition.function.base}
        transitionDuration={styles.transition.timing.base}
        aria-expanded={isExpanded}
        aria-controls={panelId}
        data-testid={headerTestId}
        {...headerBoxProps}
      >
        {header}

        <IconChevronDown
          transition={`transform ${timings.base}ms ease`}
          transform={isExpanded ? 'rotateX(180deg)' : undefined}
        />
      </Box>
      <Box
        id={panelId}
        role="region"
        display="grid"
        transitionProperty="grid-template-rows, opacity, visibility"
        transitionDuration={`${timings.slowest}ms`}
        transitionTimingFunction="ease"
        // This lets us transition the height from zero to the height of the content
        gridTemplateRows={isExpanded ? '1fr' : '0fr'}
        opacity={isExpanded ? 1 : 0}
        visibility={isExpanded ? 'visible' : 'hidden'}
      >
        <Box overflow="hidden">{children}</Box>
      </Box>
    </Box>
  )
}
