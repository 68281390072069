import { createLocalizedPathnamesNavigation } from 'next-intl/navigation'
import type { ComponentPropsWithoutRef, Ref } from 'react'

import { browserEnvs } from './envs'
import { getAvailableLocales } from './locale'
import { pathnames } from './routes'
import { logErrorWithCause } from './utils/error'

// Navigation primitives that automatically handle locale prefixing
// See https://next-intl-docs.vercel.app/docs/routing/navigation

const {
  Link: NextIntlLink,
  useRouter: useNextIntlRouter,
  usePathname: useNextIntlPathname,
  getPathname: nextIntlGetPathname,
  redirect: nextIntlRedirect,
} = createLocalizedPathnamesNavigation({
  locales: getAvailableLocales(browserEnvs.NEXT_PUBLIC_COUNTRY),
  localePrefix: 'as-needed',
  pathnames,
})
export type LinkProps = Omit<ComponentPropsWithoutRef<typeof NextIntlLink>, 'href' | 'ref'> & {
  href?: string
  ref?: Ref<HTMLAnchorElement>
}

const isExternal = (href: string) => {
  try {
    return href.match(/https?:\/\/\S*/g) != null
  } catch (error) {
    logErrorWithCause('Failed external URL check', error)
    // Treating every unknown case as external
    return true
  }
}

/**
 * Wrapped version of `next/link` that automatically handles locale prefixing and makes the `href` prop optional.
 */
export const Link = ({ href = '', ...props }: LinkProps) => {
  if (isExternal(href)) {
    return <a href={href} {...props} />
  }

  return <NextIntlLink href={href} {...props} />
}

/**
 * Wrapped version of `useRouter` that automatically handles locale prefixing.
 */
export const useRouter = useNextIntlRouter

/**
 * Wrapped version of `usePathname` that doesn't include the locale prefix.
 */
export const usePathname = useNextIntlPathname

export const getPathname = nextIntlGetPathname

export const redirect = nextIntlRedirect
