'use client'

import { useTranslations } from 'next-intl'

import { Box, spacing } from '@fortum/elemental-ui'

import { StyledLink } from '@/shared/components/StyledLink'
import type { LinkEntry } from '@/shared/contentful/types'
import { getLinkEntryUrl } from '@/shared/contentful/utils'
import { useTheme } from '@/shared/hooks/useTheme'

type Props = {
  legalLinks: LinkEntry[]
}

/**
 * Footer quick links.
 */
export const LegalLinks = ({ legalLinks }: Props) => {
  const theme = useTheme()
  const t = useTranslations('footer')

  return (
    <Box
      tag="ul"
      display="flex"
      flexWrap="wrap"
      flexDirection={{ default: 'column', s: 'row' }}
      alignItems="center"
      justifyContent="center"
      columnGap={spacing.xs}
      rowGap={spacing.xxs}
    >
      <Box tag="li">
        <StyledLink
          href="#_"
          className="optanon-show-settings"
          data-testid="footer-cookie-link"
          color={theme.colors.textLightOnDark}
          isHoverDisabled
        >
          {t('cookieSettings')}
        </StyledLink>
      </Box>
      {legalLinks.map(({ sys, ...fields }) => (
        <Box tag="li" key={sys.id}>
          <StyledLink
            href={getLinkEntryUrl(fields)}
            color={theme.colors.textLightOnDark}
            data-testid="footer-legal-link"
            isHoverDisabled
          >
            {fields.label}
          </StyledLink>
        </Box>
      ))}
    </Box>
  )
}
