import { type Country, browserEnvs } from '../envs'
import type { Locale } from '../locale'
import { getDefaultLocale, getPathPrefix } from '../locale'
import { getPathname } from '../navigation'
import { routes } from '../routes'
import type { AnyEntry, ArticleEntry, EnterprisePageEntry, LinkEntry, PageEntry } from './types'
import { isNotNullOrUndefined } from '../utils/isNotNullOrUndefined'
import { addHttpsProtocol } from '../utils/url'
import { isArticleEntry, isTypeEnterprisePageEntry } from './types/contentTypeGuards'

export type Page = Pick<
  PageEntry | EnterprisePageEntry,
  'slug' | 'title' | 'breadcrumb' | '__typename' | 'parentPage'
>
export type PathParams =
  | Page
  | Pick<ArticleEntry, 'slug' | 'title' | 'breadcrumb' | '__typename' | 'customerType'>

export const getPageSlugParams = (page: PathParams): string[] => {
  // Articles do not have parent page
  if (page.__typename === 'Article') {
    return [page.slug].filter(Boolean)
  }

  const parents = getParentPages(page)

  const stripLeadingSlash = (slug: string) => slug.replace(/^\/+/, '')

  return parents
    .map((parent) => parent.slug)
    .concat(page.slug)
    .filter(Boolean)
    .map(stripLeadingSlash)
    .filter(Boolean)
}

/**
 * Gets the full path of a page or article, including its parent pages. Including segment structure.
 *
 * Should be used for any navigation purposes.
 *
 * For example for enterprisePage will add prefix /enterprise.
 * For article will append custom localized prefix.
 */
export const getPageRoutePath = (page: PathParams, locale?: Locale): string => {
  const slugs = getPageSlugParams(page)
  const localeValue = locale ?? getDefaultLocale(browserEnvs.NEXT_PUBLIC_COUNTRY)

  if (isArticleEntry(page)) {
    return getPathname({
      locale: localeValue,
      href: {
        pathname: page.customerType?.includes('Private')
          ? routes.BLOG_ARTICLE
          : routes.ENTERPRISE_BLOG_ARTICLE,
        params: {
          slug: page.slug,
        },
      },
      // Typings for next-intl params are not working as expected, we use intentionally "any" casting to skip TS check here
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any)
  }

  const homePrefix = getPathname({
    href: isTypeEnterprisePageEntry(page) ? routes.ENTERPRISE_HOME : routes.HOME,
    locale: localeValue,
  })

  const root = homePrefix === '/' ? homePrefix : `${homePrefix}/`

  return `${root}${slugs.join('/')}`
}

/**
 * Gets the full path of a page or article, including its parent pages.
 *
 * Should be used to identify pathname with the Contentful data
 *
 * For example: `/products-and-services/addons/ev-charging`
 */
export const getPagePath = (page: PathParams): string => {
  const slugs = getPageSlugParams(page)

  return `/` + slugs.join('/')
}

/**
 * Gets the full URL of a page or article, including the base URL, locale prefix and path.
 */
export const getPageUrl = (
  baseUrl: string,
  page: PathParams,
  country: Country,
  locale: Locale | undefined,
) => {
  const url = baseUrl + getPathPrefix(country, locale) + getPageRoutePath(page, locale as Locale)
  return url.replace(/\/$/, '') // Remove trailing slash
}

/**
 * Recursively gets all parent pages of a page or article as an array.
 * The first item is the root parent, the last item is the direct parent.
 */
export const getParentPages = (page: Page, parents: PathParams[] = []): PathParams[] => {
  const parentPage = page?.parentPage

  if (!parentPage) {
    return parents.toReversed()
  }

  parents.push(parentPage)
  return getParentPages(parentPage, parents)
}

type LinkEntryParams = Pick<LinkEntry, 'page' | 'url'>

export const getLinkEntryUrl = ({ page, url }: LinkEntryParams): string => {
  if (page) {
    return getPageRoutePath(page)
  }
  if (url) {
    return addHttpsProtocol(url)
  }
  return ''
}

export const getAssetUrl = (
  asset: { url?: string | null } | undefined | null,
): string | undefined => {
  const url = asset?.url
  return url ? resolveAssetUrl(url) : undefined
}

export const resolveAssetUrl = (url: string): string =>
  url.startsWith('//') ? `https:${url}` : url

export const orderById = <T extends AnyEntry>(
  ids: string[],
  data?: (T | null | undefined)[],
): T[] => {
  return ids.map((id) => data?.find((d) => d?.sys?.id === id))?.filter(isNotNullOrUndefined)
}

type CustomerServiceLinkUrl = {
  sys: {
    urn: string
    linkType: string
  }
}

export const getCustomerServiceIdByUrl = (customerServiceLink?: CustomerServiceLinkUrl | null) => {
  return customerServiceLink?.sys?.urn.split('/').at(-1) ?? ''
}

export const parseContentfulVariables = (
  str: string,
  variables: { [key: string]: string },
): string => {
  let result = str

  for (const key in variables) {
    const regex = new RegExp(`{{${key}}}`, 'g')
    result = result.replace(regex, variables[key])
  }

  return result
}
