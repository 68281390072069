'use client'

import { createContext, useContext } from 'react'

import type { MainLayoutContentData } from '../contentful/service'

const MainLayoutContentContext = createContext<MainLayoutContentData>({
  serviceAnnouncementLoggedIns: [],
  serviceAnnouncements: [],
  topNavigationLinks: [],
})

export type MainLayoutContentProviderProps = {
  content: MainLayoutContentData
  children: React.ReactNode
}

export const MainLayoutContentProvider = ({
  content,
  children,
}: MainLayoutContentProviderProps): JSX.Element => {
  return (
    <MainLayoutContentContext.Provider value={content}>
      {children}
    </MainLayoutContentContext.Provider>
  )
}

/**
 * Return all required data for main layout data fetched from Contentful GraphQL
 */
export const useMainLayoutContent = () => {
  const context = useContext(MainLayoutContentContext)
  if (!context) {
    throw new Error('useMainLayoutContent must be used within a MainLayoutContentContextProvider')
  }

  return context
}
