'use client'

import { Box, ContentText, spacing } from '@fortum/elemental-ui'

import { Icon, type IconId } from '@/shared/components/Icon'
import { StyledLink } from '@/shared/components/StyledLink'
import type { IconLinkEntry } from '@/shared/contentful/types'
import { getLinkEntryUrl } from '@/shared/contentful/utils'
import { useTheme } from '@/shared/hooks/useTheme'

type Props = {
  socialLinksTitle: string | undefined | null
  socialLinks: IconLinkEntry[]
}

/**
 * Footer social media links.
 */
export const SocialLinks = ({ socialLinksTitle, socialLinks }: Props) => {
  const theme = useTheme()

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={{ default: spacing.xxs, l: spacing.xxxs }}
      alignItems={{ default: 'center', l: 'end' }}
    >
      {socialLinksTitle && (
        <ContentText color={theme.colors.textLightOnDark} data-testid="social-title">
          {socialLinksTitle}
        </ContentText>
      )}
      <Box tag="ul" display="flex" gap={spacing.xxs}>
        {socialLinks.map(({ sys, ...fields }) => (
          <Box tag="li" key={sys.id}>
            <StyledLink
              title={fields.label || ''}
              href={getLinkEntryUrl(fields)}
              display="flex"
              color={theme.colors.textLightOnDark}
              data-testid="footer-social-link"
              isHoverDisabled
            >
              <Icon icon={fields.icon as IconId} size={48} />
            </StyledLink>
          </Box>
        ))}
      </Box>
    </Box>
  )
}
