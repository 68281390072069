import { Box, ContentText, FortumLogo, spacing } from '@fortum/elemental-ui'

import { MobileAppDownload } from '@/shared/components/MobileAppDownload'
import type { FooterEntry } from '@/shared/contentful/types'
import { useTheme } from '@/shared/hooks/useTheme'
import { rejectNil } from '@/shared/utils/array'

import { FooterColumn } from './FooterColumn'
import { QuickLinks } from './QuickLinks'
import { SeparatorRow } from './SeparatorRow'

type Props = Pick<
  FooterEntry,
  'appLinks' | 'freeTextTitle' | 'freeText' | 'quickLinksTitle' | 'quickLinksCollection'
>

/**
 * First row of the footer component.
 */
export const FirstRow = (props: Props) => {
  const theme = useTheme()

  const { appLinks, freeTextTitle, freeText, quickLinksTitle } = props

  const quickLinks = props.quickLinksCollection ? rejectNil(props.quickLinksCollection?.items) : []

  return (
    <>
      <Box
        pt={spacing.s}
        pb={{ default: 0, s: spacing.s }}
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        columnGap={{ default: '0.75rem', s: spacing.xxs, l: spacing.xs }}
        rowGap={{ default: 0, s: spacing.xxs }}
        data-testid="first-row"
      >
        <Box
          gridColumn={{ default: 'span 12', s: 'span 6', l: 'span 3' }}
          display="flex"
          flexDirection="column"
          alignItems={{ default: 'center', s: 'flex-start' }}
          gap={{ default: spacing.xs, m: spacing.s }}
          pb={spacing.xxs}
        >
          <FortumLogo p={0} color={theme.colors.textLightOnDark} />
          {appLinks && <MobileAppDownload {...appLinks} />}
        </Box>
        {quickLinks.length > 0 && (
          <Box
            gridColumn={{ default: 'span 12', s: 'span 6', l: 'span 3' }}
            data-testid="footer-quick-links"
          >
            <FooterColumn title={quickLinksTitle} panelId="footer-quick-links">
              <QuickLinks quickLinks={quickLinks} />
            </FooterColumn>
          </Box>
        )}
        {freeText && (
          <Box
            gridColumn={{ default: 'span 12', s: 'span 6', l: 'span 3' }}
            data-testid="footer-free-text"
          >
            <FooterColumn title={freeTextTitle} panelId="footer-free-text">
              <ContentText color={theme.colors.textLightOnDark} data-testid="free-text">
                {freeText}
              </ContentText>
            </FooterColumn>
          </Box>
        )}
      </Box>
      <SeparatorRow />
    </>
  )
}
