/* eslint-disable @typescript-eslint/no-explicit-any */
import { z } from 'zod'

type GetEnvsBaseProps = {
  /** @description By default an error will be thrown whenever server env variable is being exposed to browser, use this property to disable this behavior */
  disableThrowOnServerEnvsExposure?: boolean
  /** @description By default an error will be thrown whenever invalid env variable is found UNLESS the code is being run in GitHub Actions, you can either disable throwing an error completely or provide a testing function of your own choosing. Be careful though! Disabling this may cause runtime exceptions!  */
  shouldThrowOnInvalidEnvs?: false | (() => boolean)
}

type GetServerEnvsProps<S extends z.ZodType> = {
  serverSchema: S
} & GetEnvsBaseProps

type GetBrowserEnvsProps<B extends z.ZodType> = {
  browserSchema: B
  browserEnvs: Record<string, unknown>
} & GetEnvsBaseProps

type GetEnvsProps<B extends z.ZodType, S extends z.ZodType> =
  | (GetBrowserEnvsProps<B> & GetServerEnvsProps<S>)
  | (GetBrowserEnvsProps<B> | GetServerEnvsProps<S>)

type GetOutput<A> = A extends z.ZodType ? ReturnType<A['parse']> : never

type GetEnvsPartialResult<B extends z.ZodType, Label extends string> = [B] extends [never]
  ? Record<string, never>
  : B extends z.ZodType
    ? { [key in Label as `${key}Envs`]: GetOutput<B> }
    : Record<string, never>

type GetEnvsResult<B extends z.ZodType, S extends z.ZodType> = [B] extends [never]
  ? [S] extends [never]
    ? Record<string, never>
    : GetEnvsPartialResult<S, 'server'>
  : [S] extends [never]
    ? GetEnvsPartialResult<B, 'browser'>
    : GetEnvsPartialResult<S, 'server'> & GetEnvsPartialResult<B, 'browser'>

const isWithBrowser = (props: GetEnvsProps<any, any>): props is GetBrowserEnvsProps<any> =>
  (props as GetBrowserEnvsProps<any>)['browserSchema'] != null
const isWithServer = (props: GetEnvsProps<any, any>): props is GetServerEnvsProps<any> =>
  (props as GetServerEnvsProps<any>)['serverSchema'] != null

/**
 * This function is using overloading to enforce proper arguments are passed to the function (e.g. if you use browser
 * schema you have to pass browserEnvs object as well, the reason for it is the way how Next.js handles envs in browser)
 *
 * Due to overloading, you can use getEnvs in 3 ways: get browser envs, get server envs, get both browser and server envs
 *
 * To return properly typed envs object this function is using two generic types - B for browser schema and S for server schema
 */
export function getEnvs<B extends z.ZodType, S extends z.ZodType>(
  props: GetBrowserEnvsProps<B> & GetServerEnvsProps<S>,
): GetEnvsResult<B, S>
export function getEnvs<B extends z.ZodType>(props: GetBrowserEnvsProps<B>): GetEnvsResult<B, never>
export function getEnvs<S extends z.ZodType>(props: GetServerEnvsProps<S>): GetEnvsResult<never, S>
// eslint-disable-next-line func-style
export function getEnvs<B extends z.ZodType, S extends z.ZodType>(
  props: GetEnvsProps<B, S>,
): GetEnvsResult<B, S> {
  const formatErrors = (parsed: z.SafeParseReturnType<Record<string, unknown>, any>) => {
    if (parsed.success) {
      return
    }
    const errors = Object.entries(parsed.error.format())
      .map(([name, value]) => {
        if (value && '_errors' in value) {
          return `${name}: ${value._errors.join(', ')}\n`
        }
      })
      .filter(Boolean)
    console.error(...errors)
  }

  let browserParsed: z.SafeParseReturnType<unknown, unknown> | undefined
  let serverParsed: z.SafeParseReturnType<unknown, unknown> | undefined

  if (isWithBrowser(props)) {
    const { browserSchema, disableThrowOnServerEnvsExposure } = props
    const exposedKeys = Object.keys(props.browserEnvs).filter((key) => !key.includes('NEXT_PUBLIC'))
    if (exposedKeys.length > 0) {
      console.error(
        '❌ Server environment variables are being exposed:\n',
        exposedKeys.join('\n'),
        '\n',
      )
      if (!disableThrowOnServerEnvsExposure) {
        throw new Error('Server environment variables exposed')
      }
    }
    browserParsed = browserSchema.safeParse(props.browserEnvs)
  }
  if (isWithServer(props)) {
    const { serverSchema } = props
    serverParsed = serverSchema.safeParse({
      ...process.env,
    })
  }

  const isBrowser = typeof window !== 'undefined'

  if (serverParsed) {
    // if the context is browser set serverParsed to true (serverParsed will always fail in browser since server-side
    // envs are only available in server context)
    serverParsed.success = isBrowser || serverParsed.success
    if (serverParsed.success && isBrowser) {
      serverParsed.data = {} as typeof serverParsed.data
    }
  }

  if ((browserParsed && !browserParsed.success) || (serverParsed && !serverParsed.success)) {
    console.error('❌ Invalid environment variables:')
    if (browserParsed) {
      formatErrors(browserParsed)
    }
    if (serverParsed) {
      formatErrors(serverParsed)
    }
    const shouldThrow =
      typeof props.shouldThrowOnInvalidEnvs === 'function'
        ? props.shouldThrowOnInvalidEnvs()
        : (props.shouldThrowOnInvalidEnvs ?? !process.env.GITHUB_ACTIONS)
    if (shouldThrow) {
      throw new Error('Invalid environment variables')
    }
  }

  // type casting is necessary if we want to keep non-optional fields and allow disabling throwing an error when invalid envs are found
  const serverEnvs = (serverParsed as z.SafeParseSuccess<GetOutput<S>> | undefined)?.data ?? {}
  const browserEnvs = (browserParsed as z.SafeParseSuccess<GetOutput<B>> | undefined)?.data ?? {}

  if (browserEnvs && serverEnvs) {
    return { browserEnvs, serverEnvs } as unknown as GetEnvsResult<B, S>
  }
  if (browserEnvs) {
    return { browserEnvs } as GetEnvsResult<B, S>
  }
  return { serverEnvs } as GetEnvsResult<B, S>
}

export const strictBoolean = z
  .union([z.enum(['true', 'false']), z.boolean()])
  .transform((value) => value === 'true' || value === true)
