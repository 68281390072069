import { datadogRum } from '@datadog/browser-rum'

/**
 * Log an error to the console and Datadog RUM.
 * Logging to RUM only works in the client and does nothing on the server.
 */
export const logError = (error: unknown) => {
  // Convert string to Error object so that error is shown in RUM
  error = typeof error === 'string' ? new Error(error) : error
  console.error(error)
  datadogRum.addError(error)
}

/**
 * Log a new error with the given message and cause to the console and Datadog RUM.
 * Logging to RUM only works in the client and does nothing on the server.
 */
export const logErrorWithCause = (message: string, cause: unknown) => {
  const error = new Error(message, { cause })
  logError(error)
}

export type ErrorWithDigest = Error & { digest?: string }

/**
 * Log a Next.js error page error to Datadog RUM.
 * For server component errors, Next.js strips the stack trace and other sensitive data.
 * To include the error in Datadog error tracking, we need to wrap it in a new Error object.
 */
export const logNextError = (error: ErrorWithDigest) => {
  if (error.digest) {
    const message = `Error in server component render. Digest: ${error.digest}`
    error = new Error(message, { cause: error })
  }
  datadogRum.addError(error)
}
