'use client'

import { type ReactNode, useState } from 'react'

import { Box, Hidden, spacing } from '@fortum/elemental-ui'

import { useTheme } from '@/shared/hooks/useTheme'

import { SeparatorRow } from './SeparatorRow'
import { Accordion2 } from '../../Accordion2'
import { StyledHeading } from '../../StyledHeading'

type Props = {
  /**
   * Column title.
   */
  title?: string | null
  /**
   * Column content.
   */
  children?: ReactNode
  /**
   * Accordion panel id.
   */
  panelId: string
}

/**
 * Footer column that renders as an accordion on small viewports.
 */
export const FooterColumn = ({ title, children, panelId }: Props) => {
  const { colors } = useTheme()

  const [isExpanded, setExpanded] = useState(false)

  return (
    <Box>
      <Hidden below="s">
        {title && (
          <StyledHeading
            level={5}
            mb={spacing.xxs}
            color={colors.textLightOnDark}
            data-testid={`heading-${panelId}`}
          >
            {title}
          </StyledHeading>
        )}
        {children}
      </Hidden>

      <Hidden above="s">
        <SeparatorRow />
        <Accordion2
          header={
            <StyledHeading
              level={5}
              textColor={colors.textLightOnDark}
              height={spacing.xl}
              display="flex"
              alignItems="center"
            >
              {title}
            </StyledHeading>
          }
          panelId={panelId}
          isExpanded={isExpanded}
          onToggle={() => setExpanded(!isExpanded)}
          boxProps={{ mh: `-${spacing.xxxs}` }}
          headerBoxProps={{ textColor: colors.textLightOnDark, mh: spacing.xxxs }}
        >
          {/* Horizontal margin is used so that link focus outline is not clipped */}
          <Box pv={spacing.xxs} mh={spacing.xxxs}>
            {children}
          </Box>
        </Accordion2>
      </Hidden>
    </Box>
  )
}
