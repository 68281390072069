import type { FC } from 'react'

import type { IconProps as ElementalIconProps, LazyIconProps } from '@fortum/elemental-ui'
import { LazyIcon } from '@fortum/elemental-ui'

// @fortum/elemental-ui currently exports the IconId type as a string
// If that is fixed, we could remove this type and use the elemental type directly
export type IconId = LazyIconProps['iconId']

export type IconProps = ElementalIconProps & {
  icon: IconId | FC<ElementalIconProps>
}

/**
 * Wrapper component for loading ElementalUI icons dynamically
 */
export const Icon: FC<IconProps> = (props: IconProps) => {
  const { icon: Icon, ...rest } = props

  if (typeof Icon === 'function') {
    const iconName = Icon.name

    return <Icon size={props.size ?? 24} data-testid={iconName} {...rest} />
  }

  return <LazyIcon iconId={Icon} size={props.size ?? 24} data-testid={Icon} {...rest} />
}
