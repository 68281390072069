/**
 * Adds https:// to a URL if it doesn't have a recognized protocol or is a relative URL.
 * For example, `www.fortum.com` becomes `https://www.fortum.com`.
 */
export const addHttpsProtocol = (url: string): string => {
  const protocolRegex = /^(http:\/\/|https:\/\/|mailto:|tel:)/i

  if (protocolRegex.test(url)) {
    return url // Return the original URL if it already contains a recognized protocol
  }

  if (url.startsWith('/')) {
    return url
  }

  return `https://${url}`
}

export type SearchParams = Record<string, string | number | boolean | undefined>

/**
 * Creates a query string from an object of parameters.
 */
export const stringifyParams = (params: SearchParams): string => {
  const searchParams = new URLSearchParams()

  for (const [key, value] of Object.entries(params)) {
    if (typeof value === 'boolean' || typeof value === 'number') {
      searchParams.append(key, value.toString())
    } else if (typeof value === 'string') {
      searchParams.append(key, value)
    }
  }

  return searchParams.toString()
}
