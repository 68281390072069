export const repeat = (callback: VoidFunction, times: number) => {
  Array.from(new Array(times), () => callback())
}

/**
 * Remove null and undefined values from an array.
 */
export const rejectNil = <T>(array: (T | null | undefined)[]): T[] =>
  array.filter((item) => item != null) as T[]

/**
 * Create an array of numbers from 0 to count - 1.
 */
export const range = (count: number): number[] => [...new Array(count).keys()]

/**
 * Group an array of items by a key.
 */
export const groupBy = <T>(items: T[], getKey: (item: T) => string): Record<string, T[]> =>
  items.reduce(
    (groups, item) => {
      const key = getKey(item)
      if (!groups[key]) {
        groups[key] = []
      }
      groups[key].push(item)
      return groups
    },
    {} as Record<string, T[]>,
  )
