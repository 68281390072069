'use client'

import Image from 'next/image'
import styled from 'styled-components'

import { Box, spacing } from '@fortum/elemental-ui'

import type { ImageLinkEntry } from '@/shared/contentful/types'
import { getAssetUrl, getLinkEntryUrl } from '@/shared/contentful/utils'

import { StyledLink } from '../../StyledLink'

type BadgesProps = {
  badgeImages: ImageLinkEntry[]
}

/**
 * Footer badge image links.
 */
export const Badges = ({ badgeImages }: BadgesProps) => {
  const badgeSize = { default: '3rem', s: '5rem' }

  return (
    <Box tag="ul" display="flex" gap={spacing.xs}>
      {badgeImages.map(({ sys, ...fields }) => {
        const imageUrl = getAssetUrl(fields?.image?.image)
        const altText = fields.image?.altText

        return (
          imageUrl && (
            <Box tag="li" key={sys.id}>
              <StyledLink
                display="block"
                title={fields.label || ''}
                href={getLinkEntryUrl(fields)}
                width={badgeSize}
                height={badgeSize}
                data-testid="footer-badge-link"
                isHoverDisabled
              >
                <BadgeImage src={imageUrl} alt={altText ?? ''} width={80} height={80} />
              </StyledLink>
            </Box>
          )
        )
      })}
    </Box>
  )
}

const BadgeImage = styled(Image)`
  display: block;
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
`
