'use client'

import { Box } from '@fortum/elemental-ui'

import { useTheme } from '@/shared/hooks/useTheme'

/**
 * Footer row separator.
 */
export const SeparatorRow = () => {
  const theme = useTheme()

  return <Box bb={`1px solid ${theme.colors.brandPrimary}`} width="100%" />
}
