import { useNow, useTranslations } from 'next-intl'

import { ContentText } from '@fortum/elemental-ui'

import { useTheme } from '@/shared/hooks/useTheme'

/**
 * Footer copyright text.
 */
export const CopyrightText = () => {
  const t = useTranslations('footer')
  const now = useNow()
  const theme = useTheme()

  return (
    <ContentText color={theme.colors.textLightOnDark}>
      {t('copyrightText', { year: now.getFullYear() })}
    </ContentText>
  )
}
