// eslint-disable-next-line no-restricted-imports
import { createFormatter, useLocale as useLocaleInternal, useNow, useTimeZone } from 'next-intl'

import type { Locale as ConsentLocale } from '@fortum/global-consent-components'

import type { Country } from './envs'
import { browserEnvs } from './envs'

export const AVAILABLE_FULL_LOCALES = [
  'fi-FI',
  'sv-FI',
  'en-FI',
  'sv-SE',
  'nb-NO',
  'en-GB',
] as const
export const AVAILABLE_LOCALES = ['fi', 'sv', 'en', 'nb'] as const

export type FullLocale = (typeof AVAILABLE_FULL_LOCALES)[number]
export type Locale = (typeof AVAILABLE_LOCALES)[number]

interface CountryLocaleConfig {
  default: Locale
  available: Locale[]
}

type LocaleConfig = Record<Country, CountryLocaleConfig>

export const localeConfig: LocaleConfig = {
  FI: {
    default: 'fi',
    available: ['fi', 'sv', 'en'],
  },
  SE: {
    default: 'sv',
    available: ['sv'],
  },
  NO: {
    default: 'nb',
    available: ['nb'],
  },
  GB: {
    default: 'en',
    available: ['en', 'fi', 'sv', 'nb'],
  },
}

export const getDefaultLocale = (country: Country): Locale => localeConfig[country].default
export const getAvailableLocales = (country: Country): Locale[] => localeConfig[country].available

export const isLocaleAvailable = (country: Country, locale: string): locale is Locale =>
  getAvailableLocales(country).includes(locale)

export const getFullLocale = (country: Country, locale: Locale): FullLocale =>
  `${locale}-${country}` as FullLocale

/**
 * Get the current locale, e.g. `fi`. Can also be used in server components.
 * Will throw if the intl middleware has not been applied to the request.
 */
export const useLocale = (): Locale => {
  return useLocaleInternal() as Locale
}

/**
 * Get the current full locale, e.g. 'fi-FI'. Can also be used in server components.
 * Will throw if the intl middleware has not been applied to the request.
 */
export const useFullLocale = (): FullLocale => {
  const country = browserEnvs.NEXT_PUBLIC_COUNTRY
  const locale = useLocale()
  return getFullLocale(country, locale)
}

export const isLocale = (value: string): value is Locale => AVAILABLE_LOCALES.includes(value)

export const isFullLocale = (value: string): value is FullLocale =>
  AVAILABLE_FULL_LOCALES.includes(value)

/**
 * Get path prefix for the given locale, e.g. `/sv` for `sv`.
 * If the locale is the default locale, an empty string is returned.
 */
export const getPathPrefix = (country: Country, locale: string | undefined): string => {
  if (!locale || !isLocale(locale)) {
    return ''
  }
  const defaultLocale = getDefaultLocale(country)
  if (locale === defaultLocale) {
    return ''
  }
  return '/' + locale
}

/**
 * Get locale for `@fortum/global-consent-components`.
 */
export const getConsentLocale = (locale: FullLocale): ConsentLocale => {
  switch (locale) {
    case 'fi-FI':
    case 'sv-FI':
    case 'en-FI':
    case 'sv-SE':
      return locale
    case 'nb-NO':
      // TODO: Consent components doesn't support nb-NO
      return 'en-GB'
    default:
      return 'en-GB'
  }
}

/**
 * Same as `useFormatter` from `next-intl`, but uses the full locale.
 * This is required e.g. for `en-FI`, as `en` uses incorrect formatting.
 */
export const useFormatter = () => {
  const locale = useFullLocale()
  const now = useNow()
  const timeZone = useTimeZone()
  return createFormatter({ locale, now, timeZone })
}
