'use client'

import { useTranslations } from 'next-intl'

import { IconLanguage, Menu } from '@fortum/elemental-ui'

import { Icon } from '@/shared/components/Icon'
import type { LinkEntry } from '@/shared/contentful/types'
import { getLinkEntryUrl } from '@/shared/contentful/utils'
import { useTheme } from '@/shared/hooks/useTheme'
import { useRouter } from '@/shared/navigation'

type Props = {
  countryLinks: LinkEntry[]
}

/**
 * Footer country links menu.
 */
export const CountryMenu = ({ countryLinks }: Props) => {
  const theme = useTheme()
  const t = useTranslations('footer')
  const router = useRouter()

  const url = useFullUrl()

  const items = countryLinks.map((fields) => ({
    name: fields.label ?? '',
    value: getLinkEntryUrl(fields),
  }))

  const selected = items.find((item) => url?.startsWith(item.value))

  const handleSelect = (value: unknown) => {
    if (typeof value === 'string' && value !== selected?.value) {
      router.push(value)
    }
  }

  return (
    <Menu
      icon={<Icon icon={IconLanguage} />}
      aria-label={t('countryMenuLabel')}
      onSelect={handleSelect}
      selected={selected?.value}
      items={items}
      label={selected?.name ?? t('countryMenuLabel')}
      variant="condensed"
      color={theme.colors.textLightOnDark}
      dropdownDirection="up"
      data-testid="footer-country-menu"
    />
  )
}

const useFullUrl = () => {
  if (typeof window !== 'undefined') {
    return window.location.origin + window.location.pathname
  }
}
